
/**
 * @name Router
 * @desc 页面路由配置
 */

// 导入库
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
// 导入框架
import Frame from "../component/layout.js";

// 导入组件
import Home from '../pages/Home'
import Intro from '../pages/Intro'
import Net from '../pages/Net'
import Plan from '../pages/Plan'
import Prop from '../pages/Prop'
import Scrb from '../pages/Scrb'
import Spec from '../pages/Spec'
import Struc from '../pages/Struc'
// import Test from '../pages/Test'


class ReactRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Route path="/test" exact component={Test}></Route> */}
          <Route path="/">

            <Redirect to="/scrb" />
            <Frame>
              <Switch>
                <Route path="/home" exact component={Home}></Route>
                <Route path="/intro" exact component={Intro}></Route>
                <Route path="/Net" exact component={Net}></Route>
                <Route path="/Plan" exact component={Plan}></Route>
                <Route path="/Prop" exact component={Prop}></Route>
                <Route path="/Scrb" exact component={Scrb}></Route>
                <Route path="/Spec" exact component={Spec}></Route>
                <Route path="/Struc" exact component={Struc}></Route>
             
              </Switch>
            </Frame>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default ReactRouter;

