import { fromJS } from "immutable";
import * as constants from "./actionTypes";

const defaultState = fromJS({
  selected: 1,
  loading: true,
  path: 'https://starbucks-vm.oss-cn-zhangjiakou.aliyuncs.com/dj2/1.mp4',
  createUsers: JSON.parse(localStorage.getItem("createUsers")) || {},
  // 分页
  page: localStorage.getItem("page") || {
    pagenum: 1,
    pagesize: 10
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_COM:
      return state.set("selected", action.payload);
    case constants.CHANGE_PATH:
      return state.set("path", action.payload)
    case constants.CURRENT_PAGE:
      return state.update("page", val => (val = fromJS(action.payload)));
    default:
      return state;
  }
};

// 相关参考代码

// import {fromJS} from 'immutable';
// import * as constants from './actionTypes';

// export default (state=defaultState,action)=>{
//   switch(action.type){
//       //查询
//     case constants.CHANGE_SEARCH_TABLE:
//       return state.merge({
//         tableList:action.rows,
//         total:action.total
//       });
//       //分页
//     case constants.PAGE_CHANGE_DATA:
//       return state.merge({
//         tableList:action.rows,
//         total:action.total,
//         page:action.page
//   });
//     default:
//       return state;
//   }
// }

// 相关参考代码
// const defaultState = fromJS({
//   tableList:[],
//   total:''
// });

// export default (state=defaultState,action)=>{
//   switch(action.type){
//       //查询
//     case constants.CHANGE_SEARCH_TABLE:
//       return state.merge({
//         tableList:action.rows,
//         total:action.total
//       });
//       //分页
//     case constants.PAGE_CHANGE_DATA:
//       return state.merge({
//         tableList:action.rows,
//         total:action.total,
//         page:action.page
//   });
//     default:
//       return state;
//   }
// }
