// Home\index.js

/**
 * @name Home
 * @desc 首页
 */

import React, { Component } from 'react'
import { Layout, Button, BackTop } from 'antd';
import styles from './layout.module.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
const {  Content } = Layout;

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navList: ['项目简介视频', '项目团队', '项目描述', '网络要求', '技术架构', '项目特色', '服务计划', '知识产权',],

        }
    }
    componentDidMount() {
        // console.log(this);

    }
    tabbClick(params) {
        switch (params) {
            case 'home':
                this.props.history.push({
                    pathname: '/home'
                });
                break;
            case 'intro':
                this.props.history.push({
                    pathname: '/intro'
                });
                break;
            case 'scrb':
                this.props.history.push({
                    pathname: '/scrb'
                });
                break;
            case 'net':
                this.props.history.push({
                    pathname: '/net'
                });
                break;
            case 'struc':
                this.props.history.push({
                    pathname: '/struc'
                });
                break;
            case 'spec':
                this.props.history.push({
                    pathname: '/spec'
                });
                break;
            case 'plan':
                this.props.history.push({
                    pathname: '/plan'
                });
                break;
            default: {
                this.props.history.push({
                    pathname: '/prop'
                })
            }
        }
    }
    nav = data =>
        data.map((item, index) => {
            return <li key={index} onClick={() => this.tabbClick(index)}><Button type="primary">{item}</Button></li>
        })

    render() {
        return (
            <div className={styles.layout}>
                <BackTop />
                <div className={styles.home}>
                    <div className={styles.head}>
                        <h1 className={styles.title}>浙江大学口腔医学院牙周翻瓣术虚拟仿真实验</h1>
                        <ul className={styles.sub}>
                            <li>
                                <span>所属专业类：</span>
                                <span>医学技术类   </span>
                            </li>
                            <li>
                                <span>对应专业：</span>
                                <span>智能医学工程   </span>
                            </li>
                            <li>
                                <span>学校：</span>
                                <span>浙江大学   </span>
                            </li>
                            <li>
                                <span>负责人：</span>
                                <span>戚刚刚   </span>
                            </li>
                        </ul>
                        <p className={styles.subtitle}>随着信息技术的发展和普及，虚拟仿真实验技术被广泛应用于实验教学中，成为传统实验教学有效而重要的补充。传统牙周教学因其教学时间不足及教学方式单一等有其局限性。
                        随着科技的进步，虚拟仿真技术应用于口腔教学培训的成果也越来越成熟。本课题组通过搭建牙周检查及牙周翻瓣术虚拟仿真实验平台的方式，探讨虚拟仿真技术应用于牙周教学及培训的优势及存在的困难。</p>
                    </div>
                </div>
                <div className={styles.nav}>
                    <ul>
                        {/* {this.nav(navList)} */}
                        <li><Button className={this.props.selected === 3 ? styles.btn_choose : ""} onClick={() => this.tabbClick('scrb')} type="primary">项目介绍</Button></li>
                        
                        <li><Button className={this.props.selected === 2 ? styles.btn_choose : ""} onClick={() => this.tabbClick('intro')} type="primary">项目团队</Button></li>
                        
                        <li><Button className={this.props.selected === 4 ? styles.btn_choose : ""} onClick={() => this.tabbClick('net')} type="primary">设备要求</Button></li>
                        <li><Button className={this.props.selected === 1 ? styles.btn_choose : ""} onClick={() => this.tabbClick('home')} type="primary">演示视频</Button></li>
                        {/* <li><Button className={this.props.selected === 5 ? styles.btn_choose : ""} onClick={() => this.tabbClick('struc')} type="primary">技术架构</Button></li> */}
                        {/* <li><Button className={this.props.selected === 6 ? styles.btn_choose : ""} onClick={() => this.tabbClick('spec')} type="primary">项目特色</Button></li> */}
                        {/* <li><Button className={this.props.selected === 7 ? styles.btn_choose : ""} onClick={() => this.tabbClick('plan')} type="primary">服务计划</Button></li> */}
                        <li><Button className={this.props.selected === 8 ? styles.btn_choose : ""} onClick={() => this.tabbClick('prop')} type="primary">知识产权</Button></li>
                    </ul>
                </div>
                <Content
                    style={{
                        height: '100%',
                        padding: '24px 20% 48px 20%',
                        background: '#f8f8f8'
                    }}
                >
                    <div className={styles.box}> {this.props.children}</div>
                </Content>
            </div>
        )
    }

}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
    }
}
const index = withRouter(Home)
export default connect(mapStateToProps, mapDispatchToProps)(index)
// export default Home