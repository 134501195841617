import React, { Component } from 'react'
import styles from '../Home/index.module.scss'
import { connect } from 'react-redux';
// 导入action
import {
    changeCom
} from "../Home/store/actionCreators";
class Home extends Component {
    componentDidMount() {
        this.props.changeCom(3)
    }
    render() {

        return (
            <div className={styles.home}>
                <p style={{ marginTop: '5px' }} className={styles.part_title}>项目介绍</p>
                <div className={styles.part_content}>
                    <div className={styles.srcb}>
                        <p>
                            <span className={styles.content_title}>1.</span><span className={styles.content_text}>利用线上牙周翻瓣术虚拟仿真实验平台，在线完成牙周翻瓣术的理论知识、操作流程训练和知识点考核，学生提前预习牙周翻瓣术的相关基础理论和操作。</span></p>
                        <p>
                            <span className={styles.content_title}>2.</span><span className={styles.content_text}>利用牙周翻瓣术虚拟仿真实验平台，学生可以体验完整的牙周翻瓣术训练的操作过程，感受真切的临床操作环境。</span></p>
                        <p>
                            <span className={styles.content_title}>3.</span><span className={styles.content_text}>虚拟仿真实验平台可以对学生操作的过程及质量进行评价。</span>
                        </p>
                        <p>
                            <span className={styles.content_title}>4.</span><span className={styles.content_text}>建设虚实结合的牙周病学实验教学课程体系，将虚拟现实技术应用于牙周病学实验教学，采用线上线下相结合的教学方式，将虚实结合的虚拟仿真培训贯穿牙周病学教学全过程。</span>
                        </p>
                        <p>
                            <span className={styles.content_title}>5.</span><span className={styles.content_text}>学生可通过电脑端或移动端进行课前预习和课后考核，突破时空限制，帮助学生熟悉和掌握牙周病学专业操作技能和流程，并实现过程性评价和结果性评价的有机结合。</span>
                        </p>
                    </div>


                </div>
                <p className={styles.part_title}>实验课时</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>实验所属课程所占课时：</span><div className={styles.content_text}>12</div>
                    <span className={styles.content_title}>该实验项目所占课时：</span><div className={styles.content_text}>2</div>
                </div>
                <p className={styles.part_title}>实验知识点</p>
                <div className={styles.part_content}>
                    <div className={styles.content_text}>牙周翻瓣术器械的特点及使用；牙周软组织的生理形态及牙周袋的病理；翻瓣术的切口设计及技术要点；牙周翻瓣术的一般流程及操作；牙周手术用缝线的组成和分类；牙周翻瓣术常用缝合方法；牙周塞治剂的作用及使用；翻瓣术后注意事项。</div>

                </div>
                <p className={styles.part_title}>实验仪器设备（装置或软件等）</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>装置：</span><div className={styles.content_text}>Pico Neo2 VR一体机</div>
                    <span className={styles.content_title}>软件:</span><div className={styles.content_text}>浙江大学口腔医学院牙周翻瓣术虚拟仿真实验平台</div>

                </div>
                <p className={styles.part_title}>实验结果与结论要求</p>
                <div className={styles.part_content}>
                    <span className={styles.content_title}>是否记录每步实验结果：</span><div className={styles.content_text}>无</div>
                    <span className={styles.content_title}>实验结果与结论要求：</span><div className={styles.content_text}>实验报告</div>
                    <span className={styles.content_title}>其他描述:</span><div className={styles.content_text}>无</div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { // 组件中通过 this.props.键 来获取数据
        // 键: state.toJS().模块名.键
        selected: state.toJS().home.selected
    }
}
const mapDispatchToProps = dispatch => {
    return { // 组件中通过this.props.键()
        // 键: (是否传递参数) => dispatch(action一般写对象这边action封装了所以写方法) 
        changeCom: val => dispatch(changeCom(val))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)